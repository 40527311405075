import React, { useState, useEffect } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { graphql, useStaticQuery, navigate } from "gatsby"
import axios from "axios"
// Components
import { SectionTitle, Input } from "../components/styled"
import WrapperRight from "../components/WrapperRight"
import Contact from "../components/Contact"
import { NewEmployer } from "../crud/createRegister"
import { useMutation } from "@apollo/client"
import Recaptcha from "react-recaptcha"
import { toast } from "react-toastify"
import { Seo } from "../components/seo"
import useSSR from "use-ssr"
import employers_IMG from "../images/others/hands2.jpg"

const Employers = ({ data }) => {
  const { isBrowser } = useSSR()
  const [contactCompany, setContactCompany] = useState({
    CompanyName: "",
    JobTitle: "",
    FullName: "",
    Email: "",
    ConfirmEmail: "",
    PhoneNumber: "",
    Description: "",
    messageError: {},
    isVerified: false,
  })
  useEffect(() => {
    getIpInfo()
  })
  const [ip, setIp] = useState("No")
  const [ipAddress, setIpAddress] = useState("No")
  const getIpInfo = async () => {
    const res = await axios.get("https://geolocation-db.com/json/")
    setIp(res.data.IPv4)
    setIpAddress(res.data.country_name)
  }

  const [loading, setLoading] = useState(false)
  const { messageError } = contactCompany
  const emailRegex = RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)

  const handleChange = e => {
    const { name, value } = e.target
    setContactCompany(prevState => ({
      ...prevState,
      [name]: value,
    }))

    let errors = contactCompany.messageError
    switch (name) {
      case "CompanyName":
        errors.CompanyName =
          value === "" ? "必須項目を必ずご記載の上、ご送信ください。" : ""
        break
      case "FullName":
        errors.FullName =
          value === "" ? "必須項目を必ずご記載の上、ご送信ください。" : ""
        break

      case "Email":
        errors.Email =
          value === ""
            ? "必須項目を必ずご記載の上、ご送信ください。"
            : "" || emailRegex.test(value)
            ? ""
            : "これは有効なメールではありません"
        break

      case "ConfirmEmail":
        errors.ConfirmEmail =
          value !== contactCompany.Email
            ? "必須項目を必ずご記載の上、ご送信ください。"
            : ""
        break
      case "Description":
        errors.Description =
          value === "" ? "必須項目を必ずご記載の上、ご送信ください。" : ""
        break
      default:
        break
    }
  }

  const [addContactCompany] = useMutation(NewEmployer, {
    onCompleted: () => {
      navigate("/thankyou")
    },
    onError: () => {
      setLoading(false)
      toast.error("サーバーエラー")
    },
  })

  const handleRegisterContactCompany = () => {
    addContactCompany({
      variables: {
        input: {
          data: {
            CompanyName: contactCompany.CompanyName,
            JobTitle: contactCompany.JobTitle,
            FullName: contactCompany.FullName,
            Email: contactCompany.Email,
            PhoneNumber: contactCompany.PhoneNumber,
            Description: contactCompany.Description,
            IpAddress: ip,
            IpCountry: ipAddress,
          },
        },
      },
    })
  }
  const [captchaVerify, setCaptchaVerify] = useState(false)
  const recaptchaVerify = response => {
    if (response) {
      setContactCompany(prevState => ({
        ...prevState,
        isVerified: true,
      }))
    } else {
      setCaptchaVerify(true)
      setTimeout(() => {
        setCaptchaVerify(false)
      }, 3000)
    }
  }

  const formValidate = (errors, ...data) => {
    let valid = true
    Object.values(errors).forEach(val => {
      val && (valid = false)
    })
    Object.values(data).forEach(val => {
      val === "" && (valid = false)
    })
    return valid
  }
  const handleSubmit = event => {
    event.preventDefault()
    if (contactCompany.isVerified) {
      if (
        contactCompany.CompanyName !== "" &&
        contactCompany.JobTitle !== "" &&
        contactCompany.FullName !== "" &&
        contactCompany.Email !== "" &&
        contactCompany.ConfirmEmail !== "" &&
        contactCompany.Description !== ""
      ) {
        if (formValidate(contactCompany.messageError, contactCompany)) {
          setLoading(true)
          handleRegisterContactCompany()
        } else {
          toast.error("フォームが正しくありません")
        }
      } else {
        toast.error("入力内容を再度ご確認ください。")
      }
    } else {
      recaptchaVerify()
    }
  }

  const images = data.allFile.nodes
  return (
    <section className="container employers">
      <div className="row">
        <div className="col-lg-9 col-lg-9">
          <div className="employers__responsible-for-company">
            <SectionTitle>企業のご担当者様へ</SectionTitle>
            <hr className="u-line-bottom" />
            <div style={{ marginBottom: "10px" }}></div>
            {/* <img src={employers_IMG} alt="メキシコ情報" width={"100%"}/> */}
            <GatsbyImage
              image={getImage(images[3]?.childImageSharp?.gatsbyImageData)}
              alt=""
            />
            <h2>事業目標達成のお役に立てる、優秀な「社外人事部」</h2>
            <p>
              クイックグローバルメキシコは、日本で約40年にわたり人材ビジネスに取り組んできた株式会社クイックのメキシコ法人です。{" "}
              <br />
              <br />
              弊社では、クイックグループが長年にわたり蓄積してきた経験と実績を基に、貴社に最適なスタッフを採用していただくためのヒューマンリソース・ソリューションを提供してまいります。常に信頼できるプロフェッショナルな「社外人事部」として、事業目標達成のお役に立つことをお約束します。
              <br />
              <br />
              弊社がサポートをさせて頂く際には、貴社の業務を徹底的に理解することから始め、そのうえで的確な人材をご紹介致します。また、完全成功報酬型なので採用成立まで費用は一切かかりません。様々な求人ニーズにお応えする誠実で質の高いサービスを、効果的な人事戦略の一助として、ぜひご活用ください。
              <br />
              <br />
              また、クイックグローバルメキシコでは人材採用から人事・労務に関しての課題解決のため、「メキシコの人事部」というメルマガを発行しております。
              <br />
              メキシコの労務、法務、税務等の専門家にご協力いただき、皆様に有益な情報をお届けしてまいります。バックナンバーや配信登録は
              <a href="/mexico-jinjibu" rel="noopener" target="_blank">
                コチラ
              </a>
              よりご確認ください。
              <br />
              <br />
            </p>
            <p>
              これまでの求職者の方々のご意見を元にまとめた記事「
              <a
                href="/info/how-to-hire-employees-correctly"
                rel="noopener"
                target="_blank"
              >
                従業員が定着しない状態を改善するヒント
              </a>
              」もぜひご一読ください。
            </p>
          </div>
          <form className="employers__form-contact" onSubmit={handleSubmit}>
            <h2>クイックグローバルメキシコへのお問い合わせ</h2>
            <div className="form-responsive">
              <Input>
                <label htmlFor="企業名">
                  <p className="form-label">企業名 </p>
                  <span>必須</span>
                </label>
                <input
                  type="text"
                  id="企業名"
                  name="CompanyName"
                  onChange={handleChange}
                  onBlur={handleChange}
                />
                {messageError.CompanyName && (
                  <span className="field-validation">
                    {messageError.CompanyName}
                  </span>
                )}
              </Input>
              <Input>
                <label htmlFor="部署・役職">
                  <p className="form-label">部署・役職</p>
                </label>
                <input
                  type="text"
                  id="部署・役職"
                  name="JobTitle"
                  onChange={handleChange}
                  onBlur={handleChange}
                />
              </Input>
              <Input>
                <label htmlFor="お名前">
                  <p className="form-label">お名前 </p>
                  <span>必須</span>
                </label>
                <input
                  type="text"
                  id="お名前"
                  name="FullName"
                  onChange={handleChange}
                  onBlur={handleChange}
                />
                {messageError.FullName && (
                  <span className="field-validation">
                    {messageError.FullName}
                  </span>
                )}
              </Input>
              <Input>
                <label htmlFor="メールアドレス">
                  <p className="form-label">メールアドレス </p>
                  <span>必須</span>
                </label>
                <input
                  type="text"
                  id="メールアドレス"
                  name="Email"
                  onChange={handleChange}
                  onBlur={handleChange}
                />
                {messageError.Email && (
                  <span className="field-validation">{messageError.Email}</span>
                )}
              </Input>
              <Input>
                <label htmlFor="メールアドレスの再入力">
                  <p className="form-label">メールアドレスの再入力 </p>
                  <span>必須</span>
                </label>
                <input
                  type="text"
                  id="メールアドレスの再入力"
                  name="ConfirmEmail"
                  onChange={handleChange}
                  onBlur={handleChange}
                />
                {messageError.ConfirmEmail && (
                  <span className="field-validation">
                    {messageError.ConfirmEmail}
                  </span>
                )}
              </Input>
              <Input>
                <label htmlFor="電話番号">
                  <p className="form-label">電話番号</p>
                </label>
                <input
                  type="text"
                  id="電話番号"
                  name="PhoneNumber"
                  onChange={handleChange}
                  onBlur={handleChange}
                />
              </Input>
              <Input>
                <label htmlFor="お問い合わせ内容">
                  <p className="form-label">お問い合わせ内容 </p>
                  <span>必須</span>
                </label>
                <textarea
                  id="お問い合わせ内容"
                  name="Description"
                  onChange={handleChange}
                  onBlur={handleChange}
                />
                {messageError.Description && (
                  <span className="field-validation">
                    {messageError.Description}
                  </span>
                )}
              </Input>
              <div className="captcha-style">
                {isBrowser && (
                  <Recaptcha
                    render="explicit"
                    name="isVerify"
                    verifyCallback={recaptchaVerify}
                    sitekey="6Lf4VfAbAAAAAO1Fu6ZqpDdvbs929UMuN04vwA3A"
                  />
                )}
                {captchaVerify ? (
                  <span className="field-validation-captcha">
                    Please verify that you are not a robot
                  </span>
                ) : (
                  ""
                )}
              </div>
              <p>
                当社の
                <a
                  className="btn-terms-condition"
                  href="/termsofuse/"
                  target="_blank"
                  rel="noreferrer"
                >
                  利用規約・個人情報保護方針
                </a>
                に同意の上、お申込みください。
              </p>
              <div className="u-text-center">
                {loading === false ? (
                  <input
                    type="submit"
                    value="申し込む"
                    className="btn-submit"
                  />
                ) : (
                  <p className="buttonload">
                    <i className="fa fa-circle-o-notch fa-spin"></i>
                    &nbsp;&nbsp;少々お待ちください
                  </p>
                )}
              </div>
            </div>
          </form>
          <Contact lang={"ja"}/>
        </div>
        <WrapperRight />
      </div>
    </section>
  )
}

export default Employers

export const Head = ({ data }) => {
  const images = data.allFile.nodes
  return (
    <Seo
      title="企業のご担当者様へ"
      description="メキシコの求人情報の掲載、就職・転職サポートを行う転職エージェントサービス。メキシコ国内の転職だけでなく、日本からの転職もサポート。"
      url={`https://www.919mexico.com/employers`}
      image={images[3].childImageSharp.gatsbyImageData.images.fallback.src}
    />
  )
}

export const query = graphql`
  query {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)|(webp)/" }
        relativeDirectory: { eq: "others" }
      }
      sort: { order: ASC, fields: name }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            width: 1800
            quality: 100
            webpOptions: { quality: 100 }
            formats: [WEBP, AUTO]
          )
        }
      }
    }
  }
`
